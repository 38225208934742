import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Hero from '../content/Hero';
import Meta from '../../shared/Meta';
import CostEstimator from '../content/CostEstimator';
import Triple from '../content/Triple';
import VendorsToChooseFrom from '../VendorsToChooseFrom';
import CtaImage from '../content/CtaImage';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import { getUrl } from '../../../modules/helpers';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';

class WeddingCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Food ${cityConfig.vehicle_type} Wedding Catering | ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Book ${cityConfig.city_name}’s best food ${vehicleType}s for your wedding, reception, or brunch. Use our free no-obligation request form to book one or multiple ${vehicleType}s for your special day!`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Wedding Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/Wedding_HEADER.jpeg">
          <h1>Wedding Catering</h1>
          <p>Book {cityConfig.city_name}’s best food {vehicleType}s for your next event. Use our <b>free no-obligation request form</b> to book one or multiple {vehicleType}s for your special day!</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="NewSection">
          <h3>How it works</h3>
          <div className={tripleStyles.Triple_wrapper}>
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering1.png"
              title="1. Answer a few questions"
              text={`Tell us more about your upcoming wedding event and food ${vehicleType} needs via our free online form.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering2.png"
              title="2. Compare quotes"
              text={`Receive quotes from available food ${vehicleType}s and chat with vendors directly to refine details and make your decision.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering3.png"
              title="3. Book your truck"
              text={`Book your favorite food ${vehicleType}s directly with no added commission or service fees!`}
            />
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Seattles_best_wedding_cateres.jpeg" imgClass="img-bestWeddingCaterers"/>
              <div className={styles.Cta_copy}>
                <h4>{cityConfig.city_name}'s Best Wedding Caterers</h4>

                <p>We understand that planning a wedding can be a bit overwhelming, and finding the right food for that special day can be a complicated process, but don't fret...{cityConfig.city_name}'s best food {vehicleType}s are here to help you choose the perfect cuisine for your party! Wood-fired pizza, fresh Hawaiian poke, decadent edible cookie dough, and much more! We've got the best food {vehicleType}s to compliment any event and keep your guests happy! Our food {vehicleType}s can work in nearly any venue - indoor or outdoor - and can travel all over {cityConfig.state}, helping add some fun to your day with a full gourmet kitchen on wheels!</p>

                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/The_Choice_Is_Yours.jpeg" imgClass="img-choice"/>
              <div className={styles.Cta_copy}>
                <h4>The Choice Is Yours</h4>

                <p>We do the hard work for you and send your request out to our growing list of {cityConfig.city_name}’s best food {vehicleType}s to help you find the perfect meal for your guests! Choose from hundreds of cuisine options and let your guests customize individual orders during your event. Vegetarian? Vegan? Gluten-free? Our food {vehicleType}s can accommodate any dietary restriction to help create a one-of-a-kind menu for you and your guests!</p>

                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom />

        <CostEstimator title="Wedding Catering Cost Estimator"/>

        <section className="NewSection u-textCenter">
          <h3>Our Happy Customers</h3>
          <div className="u-grid-row u-mt2">
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Kyle_and_Caroline.jpeg)'}}/>
              <h6>Kyle &amp; Caroline - Kent, WA</h6>
              <p>With a wedding on a farm, traditional caterers weren't able to serve our party without a full kitchen on site. Booking a food {vehicleType} helped us bring delicious cuisine to our beautiful night!</p>
            </div>
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Chelsea.jpeg)'}}/>
              <h6>Chelsea - Bellevue, WA</h6>
              <p>We wanted to surprise our team with a food {vehicleType} to thank them for their hard work. We hired an ice cream cart and they were able to set up inside our office! People still ask when we can do it again!</p>
            </div>
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Brett.jpeg)'}}/>
              <h6>Brett - Seattle, WA</h6>
              <p>Calling caterers one by one was exhausting. We sent one request on SeattleFoodTruck.com and we had dozens of food {vehicleType}s coming to us with bids and menu options.</p>
            </div>
            <div className="u-grid-col u-grid-md-quarter u-grid-sm-half u-mb3">
              <div className={styles.CateringCustomerImg} style={{backgroundImage: 'url(https://static.seattlefoodtruck.com/photos/Tracy.jpeg)'}}/>
              <h6>Tracy - Kirkland, WA</h6>
              <p>We hired two amazing food {vehicleType}s for our anniversary, combining our love for the Pacific Northwest, with my husband's Caribbean background. SeattleFoodTruck.com made it easy for us to price shop in one easy place.</p>
            </div>
          </div>
        </section>

        <section className="NewSection">
          <div className={styles.BookingCta}>
            <h3>Think of us for your next event</h3>
            <p>Free no-obligation estimates from {cityConfig.city_name}'s best {vehicleType}s.</p>
            <a
              className="Button"
              href="/catering/register"
            >
              Food {cityConfig.vehicle_type} Catering
            </a>
          </div>
        </section>
      </div>
    );
  }
}

WeddingCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WeddingCatering);
