import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';

const CtaImage = ({ image }) => {
  return (
    <div className={styles.Cta_image} style={{ backgroundImage: `url("${image}")` }}/>
  );
}

CtaImage.propTypes = {
  image: PropTypes.string.isRequired,
};

export default CtaImage;
